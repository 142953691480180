export const SDGS = [
    {id: 1, title: 'No Poverty', color: '#D82439'},
    {id: 2, title: 'Zero Hunger', color: '#D29E37'},
    {id: 3, title: 'Good Health and Well-being', color: '#499735'},
    {id: 4, title: 'Quality Education', color: '#BB1A2A'},
    {id: 5, title: 'Gender Equality', color: '#F2381F'},
    {id: 6, title: 'Clean Water and Sanitation', color: '#22B4D7'},
    {id: 7, title: 'Affordable and Clean Energy', color: '#EFB910'},
    {id: 8, title: 'Decent Work and Economic Growth', color: '#9B173F'},
    {id: 9, title: 'Industry, Innovation and Infrastructure', color: '#F06324'},
    {id: 10, title: 'Reduced Inequality', color: '#D21562'},
    {id: 11, title: 'Sustainable Cities and Communities', color: '#F19524'},
    {id: 12, title: 'Responsible Consumption and Production', color: '#B5842D'},
    {id: 13, title: 'Climate Action', color: '#3B7940'},
    {id: 14, title: 'Life Below Water', color: '#0B90CD'},
    {id: 15, title: 'Life on Land', color: '#52B628'},
    {id: 16, title: 'Peace and Justice Strong Institutions', color: '#006395'},
    {id: 17, title: 'Partnerships to achieve the Goal', color: '#184465'},
]